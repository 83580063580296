<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import Button from 'primevue/button';
    import Checkbox from 'primevue/checkbox';
    import Dialog from 'primevue/dialog';
    import { computed, reactive, ref, watch, type ComputedRef } from 'vue';
    import { isMobile } from '@/util/breakpoints';
    import { useProductQuery } from '@/composables/product';
    import { createReusableTemplate } from '@vueuse/core';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import Badge from 'primevue/badge';
    import { RAL_COLOR } from '@/util/ral-color';

    const props = defineProps<{
        activeFacets: string[];
        showFilterDialog: boolean;
    }>();

    const emits = defineEmits<{
        'update:active-facets': [string[]];
        close: [];
    }>();

    const MAX_FACET = 5;
    const PRODUCT_FACETS_ORDER = [
        'productCategory',
        'length_feet',
        'color',
        'available.at',
        'available.de',
        'isUsed',
        'article_line',
    ];

    const { t, te } = useI18n();
    const { facets } = useProductQuery();
    const [DefineTemplate, ReuseTemplate] = createReusableTemplate();

    const temporarySelectedFacets = ref<string[]>([]);
    const selectedFacets = computed({
        get() {
            return temporarySelectedFacets.value;
        },
        set(value) {
            if (isMobile.value) {
                temporarySelectedFacets.value = value;
            } else {
                emits('update:active-facets', value);
            }
        },
    });
    const activeIndices = computed(() => Array.from({ length: Object.keys(facets).length }, (_, i) => i));
    const showMoreSet = reactive(new Set());

    watch(
        () => props.activeFacets,
        () => {
            temporarySelectedFacets.value = props.activeFacets;
        },
        { immediate: true }
    );

    const orderedFacets: ComputedRef<[Record<string, number>, string][]> = computed(() => {
        const newFacets: [Record<string, number>, string][] = [];
        for (const key of PRODUCT_FACETS_ORDER) {
            if (facets.value?.[key] != null) {
                newFacets.push([facets.value?.[key], key]);
            }
        }

        return newFacets;
    });

    function getFacetFilter(facetName: string, value: string): string {
        return `${facetName}:${value}`;
    }

    function translateFacetKey(facetKey: string): string {
        const key = firstPartOfFacetKey(facetKey);

        const messageKey = `PRODUCT.KEY.${key}`.toUpperCase();
        return getTranslationOrFallback(messageKey, key);
    }

    function cancelFiltersAndClose(): void {
        temporarySelectedFacets.value = props.activeFacets;
        emits('close');
    }

    function applyFiltersAndClose(): void {
        emits('update:active-facets', temporarySelectedFacets.value);
    }

    function clearFilter(): void {
        emits('update:active-facets', []);
    }

    function sortValues(values: Record<string, number>, category: string): { plain: string; translated: string }[] {
        const translatedValues = Object.keys(values).map((v) => {
            return { plain: v, translated: translateFacetValue(category, v) };
        });
        return translatedValues.sort((a, b) => {
            const aInt = parseInt(a.translated, 10);
            const bInt = parseInt(b.translated, 10);
            if (Number.isNaN(aInt) || Number.isNaN(bInt)) {
                return a.translated.localeCompare(b.translated);
            }
            return aInt - bInt;
        });
    }

    function translateFacetValue(facetKey: string, facetValue: string): string {
        const key = firstPartOfFacetKey(facetKey);
        //This is needed, because if we the facetValue is a number n, i18n will return the n-th character of the facetKey.
        if (Number.isNaN(parseInt(facetValue, 10))) {
            const messageKey = `PRODUCT.${key}.${facetValue}`.toUpperCase();
            return getTranslationOrFallback(messageKey, facetValue);
        }
        return facetValue;
    }

    function firstPartOfFacetKey(facetKey: string): string {
        const splitted = facetKey.split('.');
        return splitted[0] ?? facetKey;
    }

    function getTranslationOrFallback(messageKey: string, fallback: string): string {
        return te(messageKey) ? t(messageKey) : fallback;
    }
</script>
<template>
    <DefineTemplate>
        <Accordion
            v-if="Object.keys(facets ?? []).length > 0"
            :multiple="true"
            :active-index="activeIndices"
            expand-icon="pi pi-angle-down"
            collapse-icon="pi pi-angle-up"
            class="product-filters"
        >
            <AccordionTab v-for="[values, category] of orderedFacets" :key="category" class="product-filter">
                <template #header>
                    <div class="text-base-semibold-line-height-auto color-text">
                        {{ translateFacetKey(category) }}
                    </div>
                </template>
                <template v-for="(k, i) in sortValues(values, category)" :key="k">
                    <div v-if="showMoreSet.has(category) || i < MAX_FACET" class="facet">
                        <Checkbox
                            v-model="selectedFacets"
                            :input-id="getFacetFilter(category, k.plain)"
                            :value="getFacetFilter(category, k.plain)"
                            :checked="
                                selectedFacets.some((selected) => selected === getFacetFilter(category, k.translated))
                            "
                        />
                        <label v-if="category !== 'color'" class="facet-label" :for="getFacetFilter(category, k.plain)">
                            {{ translateFacetValue(category, k.translated) }}
                        </label>
                        <label v-else class="facet-label color-label" :for="getFacetFilter(category, k.plain)">
                            <template v-if="k.translated.replace(' ', '_') in RAL_COLOR">
                                <div>
                                    {{ translateFacetValue(category, k.translated) }}
                                    <span> {{ t(`RAL.${k.translated.replace(' ', '_')}`) }} </span>
                                </div>
                                <Badge
                                    :pt="{
                                        root: {
                                            style: {
                                                background: RAL_COLOR[k.translated.replace(' ', '_')],
                                                border: '1px solid #DEE2E6',
                                                width: '0.75rem',
                                                height: '0.75rem',
                                            },
                                        },
                                    }"
                                ></Badge>
                            </template>
                            <template v-else>
                                <div>
                                    {{ translateFacetValue(category, k.translated) }}
                                </div>
                            </template>
                        </label>
                    </div>
                    <Button
                        v-else-if="i === MAX_FACET"
                        link
                        class="show-more-less-btn"
                        @click="showMoreSet.add(category)"
                        >{{ t('PRODUCT.SHOW_MORE_FILTER') }}
                    </Button>
                    <Button
                        v-if="showMoreSet.has(category) && i === Object.keys(values).length - 1"
                        link
                        class="show-more-less-btn"
                        @click="showMoreSet.delete(category)"
                        >{{ t('PRODUCT.SHOW_LESS_FILTER') }}
                    </Button>
                </template>
            </AccordionTab>
        </Accordion>
        <div v-else class="center inner-filter-container">
            <LoadingSpinner></LoadingSpinner>
        </div>
    </DefineTemplate>

    <Dialog
        v-if="isMobile"
        :visible="showFilterDialog"
        class="p-dialog-maximized"
        @update:visible="cancelFiltersAndClose"
    >
        <template #header>
            <div class="full-width">
                <h3 class="text-xl-bold-line-height-auto color-text">{{ t('PRODUCT.FILTER_DIALOG_TITLE') }}</h3>
                <Button
                    v-if="selectedFacets.length > 0"
                    link
                    class="text-base-semibold-line-height-auto full-width clear-filter-mobile"
                    @click="clearFilter"
                >
                    {{ t('PRODUCT.DELETE_ALL_FILTER') }}
                </Button>
            </div>
        </template>

        <ReuseTemplate></ReuseTemplate>

        <template #footer>
            <Button type="button" outlined class="text-base-semibold-line-height-auto" @click="cancelFiltersAndClose">
                {{ t('COMMON.CANCEL') }}
            </Button>
            <Button type="button" class="text-base-semibold-line-height-auto" @click="applyFiltersAndClose">
                {{ t('PRODUCT.APPLY_FILTER') }}
            </Button>
        </template>
    </Dialog>
    <div v-else class="filter-container">
        <div class="inner-filter-container">
            <div class="filter-header-container">
                <h3 class="text-xl-bold-line-height-auto filter-header">{{ t('PRODUCT.FILTER_DIALOG_TITLE') }}</h3>
                <Button
                    v-if="selectedFacets.length > 0"
                    link
                    class="text-base-semibold-line-height-auto"
                    @click="clearFilter"
                >
                    {{ t('PRODUCT.DELETE_ALL_FILTER') }}
                </Button>
            </div>
        </div>

        <ReuseTemplate></ReuseTemplate>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .inner-filter-container {
        background: main.$color-background-bluegray;
        border-radius: 0 0 4px 4px;
    }

    .filter-header-container {
        display: flex;
        justify-content: space-between;
        background: main.$color-background-bluegray;
        border-radius: 4px 4px 0 0;
    }

    .filter-header {
        padding: main.$spacing-5;
    }

    .center {
        display: flex;
        justify-content: center;
        padding: main.$spacing-5;
    }

    .product-filters {
        :deep(.p-accordion-content) {
            background: main.$vt-c-white;
            padding: 0;
            color: main.$color-text;
            border: none;
        }

        :deep(.p-accordion-header-link) {
            background: main.$vt-c-white;
            font-weight: 500;
            justify-content: space-between;
            border: none;
        }

        :deep(.p-accordion-toggle-icon) {
            color: main.$toggle-button-active;
            order: 2;
        }

        :deep(.p-accordion-header-text) {
            order: 1;
        }

        :deep(.p-accordion-tab:last-child .facet:last-child) {
            border: none;
        }
    }

    @include main.for-breakpoint-lg {
        .product-filters {
            :deep(.p-accordion-content) {
                padding: 0 0 0 main.$spacing-5;
                background: main.$color-background-bluegray;
            }

            :deep(.p-accordion-header-link) {
                background: main.$color-background-bluegray;
            }
        }
    }

    .product-filter {
        background: main.$color-background-bluegray;
        padding: main.$spacing-5;
    }

    .facet {
        display: flex;
        align-items: center;
        gap: main.$spacing-3;
        padding-bottom: main.$spacing-5;

        &:last-child {
            border-bottom: main.$color-background-lightgray 1px solid;
        }
    }

    .facet-label {
        flex-grow: 1;
        cursor: pointer;
    }

    .color-label {
        display: flex;
        justify-content: space-between;
        padding-right: main.$spacing-6;
        align-items: center;
    }

    .show-more-less-btn {
        padding-left: 0;
        font-weight: 500;
        width: 100%;
        border-bottom: main.$color-background-lightgray 1px solid;
        padding-bottom: main.$spacing-5;
    }

    .clear-filter-mobile {
        padding: main.$spacing-5 main.$spacing-5 main.$spacing-5 0;
    }
</style>
