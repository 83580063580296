export const RAL_COLOR: Record<string, string> = {
    RAL_1000: '#CDBA88',
    RAL_1001: '#D0B084',
    RAL_1002: '#D2AA6D',
    RAL_1003: '#F9A900',
    RAL_1004: '#E49E00',
    RAL_1005: '#CB8F00',
    RAL_1006: '#E19000',
    RAL_1007: '#E88C00',
    RAL_1011: '#AF8050',
    RAL_1012: '#DDAF28',
    RAL_1013: '#E3D9C7',
    RAL_1014: '#DDC49B',
    RAL_1015: '#E6D2B5',
    RAL_1016: '#F1DD39',
    RAL_1017: '#F6A951',
    RAL_1018: '#FACA31',
    RAL_1019: '#A48F7A',
    RAL_1020: '#A08F65',
    RAL_1021: '#F6B600',
    RAL_1023: '#F7B500',
    RAL_1024: '#BA8F4C',
    RAL_1026: '#FFFF00',
    RAL_1027: '#A77F0F',
    RAL_1028: '#FF9C00',
    RAL_1032: '#E2A300',
    RAL_1033: '#F99A1D',
    RAL_1034: '#EB9C52',
    RAL_1035: '#8F8370',
    RAL_1036: '#806440',
    RAL_1037: '#F09200',
    RAL_2000: '#DA6E00',
    RAL_2001: '#BA481C',
    RAL_2002: '#BF3922',
    RAL_2003: '#F67829',
    RAL_2004: '#E25304',
    RAL_2005: '#FF4D08',
    RAL_2007: '#FFB200',
    RAL_2008: '#EC6B22',
    RAL_2009: '#DE5308',
    RAL_2010: '#D05D29',
    RAL_2011: '#E26E0F',
    RAL_2012: '#D5654E',
    RAL_2013: '#923E25',
    RAL_2017: '#FC5500',
    RAL_3000: '#A72920',
    RAL_3001: '#9B2423',
    RAL_3002: '#9B2321',
    RAL_3003: '#861A22',
    RAL_3004: '#6B1C23',
    RAL_3005: '#59191F',
    RAL_3007: '#3E2022',
    RAL_3009: '#6D342D',
    RAL_3011: '#782423',
    RAL_3012: '#C5856D',
    RAL_3013: '#972E25',
    RAL_3014: '#CB7375',
    RAL_3015: '#D8A0A6',
    RAL_3016: '#A63D30',
    RAL_3017: '#CA555D',
    RAL_3018: '#C63F4A',
    RAL_3020: '#BB1F11',
    RAL_3022: '#CF6955',
    RAL_3024: '#FF2D21',
    RAL_3026: '#FF2A1C',
    RAL_3027: '#AB273C',
    RAL_3028: '#CC2C24',
    RAL_3031: '#A63437',
    RAL_3032: '#701D24',
    RAL_3033: '#A53A2E',
    RAL_4001: '#816183',
    RAL_4002: '#8D3C4B',
    RAL_4003: '#C4618C',
    RAL_4004: '#651E38',
    RAL_4005: '#76689A',
    RAL_4006: '#903373',
    RAL_4007: '#47243C',
    RAL_4008: '#844C82',
    RAL_4009: '#9D8692',
    RAL_4010: '#BB4077',
    RAL_4011: '#6E6387',
    RAL_4012: '#6A6B7F',
    RAL_5000: '#304F6E',
    RAL_5001: '#0E4C64',
    RAL_5002: '#00387A',
    RAL_5003: '#1F3855',
    RAL_5004: '#191E28',
    RAL_5005: '#005387',
    RAL_5007: '#376B8C',
    RAL_5008: '#2B3A44',
    RAL_5009: '#215F78',
    RAL_5010: '#004F7C',
    RAL_5011: '#1A2B3C',
    RAL_5012: '#0089B6',
    RAL_5013: '#193153',
    RAL_5014: '#637D96',
    RAL_5015: '#007CAF',
    RAL_5017: '#005B8C',
    RAL_5018: '#048B8C',
    RAL_5019: '#005E83',
    RAL_5020: '#00414B',
    RAL_5021: '#007577',
    RAL_5022: '#222D5A',
    RAL_5023: '#41698C',
    RAL_5024: '#6093AC',
    RAL_5025: '#20697C',
    RAL_5026: '#0F3052',
    RAL_6000: '#3C7460',
    RAL_6001: '#366735',
    RAL_6002: '#325928',
    RAL_6003: '#50533C',
    RAL_6004: '#024442',
    RAL_6005: '#114232',
    RAL_6006: '#3C392E',
    RAL_6007: '#2C3222',
    RAL_6008: '#36342A',
    RAL_6009: '#27352A',
    RAL_6010: '#4D6F39',
    RAL_6011: '#6B7C59',
    RAL_6012: '#2F3D3A',
    RAL_6013: '#7C765A',
    RAL_6014: '#474135',
    RAL_6015: '#3D3D36',
    RAL_6016: '#00694C',
    RAL_6017: '#587F40',
    RAL_6018: '#60993B',
    RAL_6019: '#B9CEAC',
    RAL_6020: '#37422F',
    RAL_6021: '#8A9977',
    RAL_6022: '#3A3327',
    RAL_6024: '#008351',
    RAL_6025: '#5E6E3B',
    RAL_6026: '#005F4E',
    RAL_6027: '#7EBAB5',
    RAL_6028: '#315442',
    RAL_6029: '#006F3D',
    RAL_6032: '#237F52',
    RAL_6033: '#45877F',
    RAL_6034: '#7AADAC',
    RAL_6035: '#194D25',
    RAL_6036: '#04574B',
    RAL_6037: '#008B29',
    RAL_6038: '#00B51B',
    RAL_6039: '#B3C43E',
    RAL_7000: '#7A888E',
    RAL_7001: '#8C979C',
    RAL_7002: '#817863',
    RAL_7003: '#797669',
    RAL_7004: '#9A9B9B',
    RAL_7005: '#6B6E6B',
    RAL_7006: '#766A5E',
    RAL_7008: '#745F3D',
    RAL_7009: '#5D6058',
    RAL_7010: '#585C56',
    RAL_7011: '#52595D',
    RAL_7012: '#575D5E',
    RAL_7013: '#575044',
    RAL_7015: '#4F5358',
    RAL_7016: '#383E42',
    RAL_7021: '#2F3234',
    RAL_7022: '#4C4A44',
    RAL_7023: '#808076',
    RAL_7024: '#45494E',
    RAL_7026: '#374345',
    RAL_7030: '#928E85',
    RAL_7031: '#5B686D',
    RAL_7032: '#B5B0A1',
    RAL_7033: '#7F8274',
    RAL_7034: '#92886F',
    RAL_7035: '#C5C7C4',
    RAL_7036: '#979392',
    RAL_7037: '#7A7B7A',
    RAL_7038: '#B0B0A9',
    RAL_7039: '#6B665E',
    RAL_7040: '#989EA1',
    RAL_7042: '#8E9291',
    RAL_7043: '#4F5250',
    RAL_7044: '#B7B3A8',
    RAL_7045: '#8D9295',
    RAL_7046: '#7E868A',
    RAL_7047: '#C8C8C7',
    RAL_7048: '#817B73',
    RAL_8000: '#89693F',
    RAL_8001: '#9D622B',
    RAL_8002: '#794D3E',
    RAL_8003: '#7E4B27',
    RAL_8004: '#8D4931',
    RAL_8007: '#70462B',
    RAL_8008: '#724A25',
    RAL_8011: '#5A3827',
    RAL_8012: '#66332B',
    RAL_8014: '#4A3526',
    RAL_8015: '#5E2F26',
    RAL_8016: '#4C2B20',
    RAL_8017: '#442F29',
    RAL_8019: '#3D3635',
    RAL_8022: '#1A1719',
    RAL_8023: '#A45729',
    RAL_8024: '#795038',
    RAL_8025: '#755847',
    RAL_8028: '#513A2A',
    RAL_8029: '#7F4031',
    RAL_9001: '#E9E0D2',
    RAL_9002: '#D6D5CB',
    RAL_9003: '#ECECE7',
    RAL_9004: '#2B2B2C',
    RAL_9005: '#0E0E10',
    RAL_9006: '#A1A1A0',
    RAL_9007: '#868581',
    RAL_9010: '#F1EDE1',
    RAL_9011: '#27292B',
    RAL_9012: '#F8F2E1',
    RAL_9016: '#F1F1EA',
    RAL_9017: '#29292A',
    RAL_9018: '#C8CBC4',
    RAL_9022: '#858583',
    RAL_9023: '#787B7A',
};
